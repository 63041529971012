import "./About.css";

const About = () => {
  return (
    <div className="about">
      <div className="about-container">
        <h2 className="hidden">About the company</h2>
        <p className="hidden">
          Bath For Less is a company driven by the mission to make high-quality
          and well-known brands accessible to all at a lower price. With a
          commitment to excellence, we strive to offer a curated selection of
          renowned bathroom products that meet the highest standards of quality
          and design. By leveraging our partnerships with established brands, we
          bring you an unparalleled range of products that combine superior
          craftsmanship with affordability. Our goal is to ensure that everyone
          can create their dream bathroom, filled with trusted and sought-after
          brands, without compromising on their budget.
        </p>
      </div>
    </div>
  );
};

export default About;
