import "./WhyUs.css";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const HomeInfo = () => {
  return (
    <div className="why-us">
      <h1 className="header hidden" style={{ color: "#072c57" }}>
        Why choose us?
      </h1>
      <div className="logos">
        <div className="logo-container hidden">
          <div className="icon-container">
            <div className="material-icon">
              <CallSplitIcon />
            </div>
          </div>
          <div className="logo hidden">
            <h3>Variety</h3>
            <p>
              Discover an extensive collection of washbasins, toilets, bathtubs,
              mixers, accessories, and tiles that cater to diverse styles and
              preferences.
            </p>
          </div>
        </div>
        <div className="logo-container hidden">
          <div className="icon-container">
            <div className="material-icon">
              <AttachMoneyIcon />
            </div>
          </div>
          <div className="logo hidden">
            <h3>Cost</h3>
            <p>
              Affordable prices on top-quality bathroom essentials, allowing you
              to create your dream space without exceeding your budget.
            </p>
          </div>
        </div>
        <div className="logo-container hidden">
          <div className="icon-container">
            <div className="material-icon">
              <LocalShippingIcon />
            </div>
          </div>
          <div className="logo hidden">
            <h3>Delivery</h3>
            <p>
              Reliable and efficient delivery service. Your bathroom items will
              arrive securely and on time.
            </p>
          </div>
        </div>
        <div className="logo-container hidden">
          <div className="icon-container">
            <div className="material-icon">
              <SupportAgentIcon />
            </div>
          </div>
          <div className="logo hidden">
            <h3>Customer Service</h3>
            <p>
              Our dedicated team is here to assist you with any inquiries or
              concerns regarding our products and services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInfo;
