import "./OurProducts.css";

import washbasin from "../img/washbasin.jpg";
import toilet from "../img/toilet.jpg";
import bathtub from "../img/bathtub.jpg";

const OurProducts = () => {
  return (
    <div className="our-products">
      <h1 className="header hidden" style={{ color: "#072c57" }}>
        Our Products
      </h1>
      <div className="products">
        <div className="product-container hidden">
          <div className="product hidden">
            <h3>Washbasins</h3>
            <p>
              Transform your bathroom into a sophisticated retreat with our
              sleek washbasins, blending modern design and practicality
            </p>
          </div>
          <div className="product-image hidden">
            <img src={washbasin} alt="" />
          </div>
        </div>
        <div className="product-container hidden">
          <div className="product-image hidden">
            <img src={toilet} alt="" />
          </div>
          <div className="product hidden">
            <h3>Toilets</h3>
            <p>
              Experience unparalleled comfort and cleanliness with our advanced
              range of toilets, designed for ultimate convenience
            </p>
          </div>
        </div>
        <div className="product-container hidden">
          <div className="product hidden">
            <h3>Bathtubs</h3>
            <p>
              Indulge in a sanctuary of tranquility with our exquisite bathtubs,
              where relaxation meets timeless elegance
            </p>
          </div>
          <div className="product-image hidden">
            <img src={bathtub} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
