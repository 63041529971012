import Home from "./Home";
import HomeInfo from "./WhyUs";
import OurProducts from "./OurProducts";
import Accessories from "./Accessories";
import Brands from "./Brands";

const Homepage = () => {
  return (
    <div className="homepage">
      <Home />
      <OurProducts />
      <Accessories />
      <HomeInfo />
      <Brands />
    </div>
  );
};

export default Homepage;
