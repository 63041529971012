import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="home-snippet hidden">
        <h1>Bath For Less</h1>
        <p>
          At Bath For Less, we specialize in bringing you the epitome of
          elegance and quality at affordable prices. Discover our carefully
          curated collection of luxurious bathtubs, exquisite washbasins,
          stylish toilets, chic bathroom accessories, captivating tiles, and
          precision-engineered mixers, all sourced from top brands at
          significantly lower rates. With our commitment to finding the best
          brands for less, you can transform your bathroom into a haven of
          refined aesthetics and timeless beauty without straining your budget.
        </p>
      </div>
    </div>
  );
};

export default Home;
