import "./Navbar.css";

import logo from "../img/logo.png";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="company-logo">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div className="links">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
    </nav>
  );
};

export default Navbar;
