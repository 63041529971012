import "./Footer.css";
import header from "../img/header.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo">
        <a href="/">
          <img src={header} alt="logo" />
        </a>
      </div>
      <div className="useful-links">
        <h3>Useful Links</h3>
        <p>
          <a href="/">Home</a>
        </p>
        <p>
          <a href="/about">About Us</a>
        </p>
        <p>
          <a href="/contact">Contact Us</a>
        </p>
      </div>
      <div className="contact-us">
        <p>
          Al Quoz Logistic Park, Al Quoz 2nd Industrial Area No.S05-605, Dubai,
          United Arab Emirates
        </p>
        <p>
          <a href="tel:+971 43 858 784">+971 43 858 784</a>
        </p>
        <p>
          <a href="mailto:anas.baalbaki@bathforless.ae">
            anas.baalbaki@bathforless.ae
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
