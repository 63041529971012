import React, { useEffect, useRef } from "react";
import Navbar from "./common/Navbar";
import Homepage from "./homepage/Homepage";
import Footer from "./common/Footer";
import About from "./about/About";
import Contact from "./contact/Contact";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const observer = useRef(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    })
  );

  useEffect(() => {
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((element) => {
      observer.current.observe(element);

      return () => {
        observer.current.unobserve(element);
      };
    });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
