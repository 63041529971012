import "./Accessories.css";

import mixer from "../img/mixer.jpg";
import accessories from "../img/accessories.jpg";
import tiles from "../img/tiles.jpg";

const Accessories = () => {
  return (
    <div className="our-accessories">
      <div className="accessories">
        <div className="accessory-container hidden">
          <div className="accessory-image hidden">
            <img src={mixer} alt="" />
          </div>
          <div className="accessory hidden">
            <h3>Mixers</h3>
            <p>
              Upgrade your space with stylish mixers that offer effortless
              control and elegant designs, elevating your experience in an
              instant
            </p>
          </div>
        </div>
        <div className="accessory-container hidden">
          <div className="accessory hidden">
            <h3>Accessories</h3>
            <p>
              Complete your bathroom makeover with stylish, functional
              accessories - the perfect finishing touches
            </p>
          </div>
          <div className="accessory-image hidden">
            <img src={accessories} alt="" />
          </div>
        </div>
        <div className="accessory-container hidden">
          <div className="accessory-image hidden">
            <img src={tiles} alt="" />
          </div>
          <div className="accessory hidden">
            <h3>Tiles</h3>
            <p>
              Immerse yourself in the captivating beauty of our exquisite tiles
              with mesmerizing patterns that create an ambiance of effortless
              elegance in any setting
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
