import "./Brands.css";

import Emco from "../img/brands/emco.png";
import Grohe from "../img/brands/grohe.png";
import Quaranta from "../img/brands/quaranta.png";
import Seranit from "../img/brands/seranit.png";
import Grannitto from "../img/brands/grannitto.png";
import Tredex from "../img/brands/tredex.png";

const Brands = () => {
  return (
    <div className="brands">
      <h1 className="header hidden" style={{ color: "#072c57" }}>
        Brands
      </h1>
      <div className="brands-container">
        <div className="brand hidden">
          <img src={Quaranta} alt="Quaranta" />
        </div>
        <div className="brand hidden">
          <img src={Grohe} alt="Grohe" />
        </div>
        <div className="brand hidden">
          <img src={Emco} alt="Emco" />
        </div>
        <div className="brand hidden">
          <img src={Tredex} alt="Tredex" />
        </div>
        <div className="brand hidden">
          <img src={Grannitto} alt="Grannitto" />
        </div>
        <div className="brand hidden">
          <img src={Seranit} alt="Seranit" />
        </div>
      </div>
    </div>
  );
};

export default Brands;
